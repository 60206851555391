export const environment = {
  production: true,
  baseUrl: 'https://localpub.solocal.com',
  apiUrl: 'https://localpub-api.solocal.com',
  bridgeRedirect:'https://v3-portal.leadformance.com/',
  publicPage:'https://reporting-localpub.solocal.com',
  globalPublic:'https://bo.theramp.co/global-public-',
  RampUrl:"https://bo.theramp.co/",
  projectServer:'3',
  googleApiKey : 'AIzaSyCYXX9YJm97Nhkz6j_EtN4eLMujnYd4DN8',
  RampData:"https://assets.rampdata.fr/"

};